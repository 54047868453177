import {
  VariantColorsResolver,
  alpha,
  darken,
  defaultVariantColorsResolver,
  isLightColor,
  parseThemeColor,
  rgba,
} from '@mantine/core'

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  if (input.variant === 'blurred') {
    return {
      background: rgba(`var(--mantine-color-${parsedColor.color}-${parsedColor.shade || 6})`, 0.2),
      hover: rgba(`var(--mantine-color-${parsedColor.color}-${parsedColor.shade || 6})`, 0.22),
      border: 'none',
      color: isLightColor(
        rgba(`var(--mantine-color-${parsedColor.color}-${parsedColor.shade || 4})`, 0.2),
      )
        ? `var(--mantine-color-black)`
        : `var(--mantine-color-white)`,
    }
  }

  return defaultResolvedColors
}
