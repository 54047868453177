'use client'
import { openSans } from '@/styles/fonts'
import { createTheme } from '@mantine/core'
import { components } from './components'
import { rem } from '@mantine/core'
import { variantColorResolver } from './variantColorResolver'

export const theme = createTheme({
  variantColorResolver: variantColorResolver,
  cursorType: 'pointer',
  fontFamily: openSans.style.fontFamily,
  components: components,
  white: '#f9f9f9',
  black: '#0d0d0d',
  radius: {
    xs: 'calc(0.125rem * var(--mantine-scale))',
    sm: 'calc(0.25rem * var(--mantine-scale))',
    md: 'calc(0.35rem * var(--mantine-scale))',
    lg: 'calc(0.75rem * var(--mantine-scale))',
    xl: 'calc(2rem * var(--mantine-scale))',
  },
  defaultRadius: 'md',
  headings: {
    fontWeight: '600',
    sizes: {
      h1: {
        fontWeight: '700',
        fontSize: rem(60),
      },
      h2: {
        fontSize: rem(38),
      },
      h3: {
        fontSize: rem(16),
      },
    },
  },
})
