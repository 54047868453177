'use client'
import { DefaultAnchor } from './Anchor'
import { DefaultButton } from './Button'
import { DefaultOverlay } from './Overlay'
import { DefaultTitle } from './Title'

export const components = {
  Anchor: DefaultAnchor,
  Button: DefaultButton,
  Title: DefaultTitle,
  Overlay: DefaultOverlay,
}
