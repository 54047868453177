import { Title } from '@mantine/core'
import classes from './Title.module.css'

export const DefaultTitle = Title.extend({
  classNames: {
    root: classes.root,
  },
  defaultProps: {
    c: 'white',
  },
})
