import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0_vfjhlx5twugqpqb6iwzgankd54/node_modules/@mantine/core/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+carousel@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_embla-carousel-react@8.3._oktvy3yj5mc6t5vtazennlrmva/node_modules/@mantine/carousel/styles.layer.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/app/node_modules/.pnpm/@mantine+notifications@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-_7wxtoqtuee2kkjetytjoopm57q/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LexicalEditorTheme"] */ "/app/node_modules/.pnpm/@payloadcms+richtext-lexical@3.0.0-beta.123_@faceless-ui+modal@3.0.0-beta.2_@faceless-ui+scro_ktpfdcq7qan5nzw7q3arbqglxi/node_modules/@payloadcms/richtext-lexical/dist/lexical/theme/EditorTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/app/node_modules/.pnpm/nuqs@2.1.1_next@15.0.1_react@19.0.0-rc-45804af1-20241021/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(app)/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+notifications@7.13.4_@mantine+core@7.13.4_@mantine+hooks@7.13.4_react-dom@19.0.0-rc-_7wxtoqtuee2kkjetytjoopm57q/node_modules/@mantine/notifications/styles.layer.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0-rc-45804af1-20241021/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"cyrillic\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-45804af1-20241021_react@19.0.0-rc-45804af1-20241021/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Oswald\",\"arguments\":[{\"subsets\":[\"cyrillic\"],\"variable\":\"--font-oswald\",\"display\":\"swap\"}],\"variableName\":\"oswald\"}");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/app/src/styles/theme.tsx");
